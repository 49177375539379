import React, { useState } from "react";
import insta_icon from "../assets/insta-icon.svg";
import X_icon from "../assets/X-icon.svg";
import spotify_icon from "../assets/spotify-icon.svg";
import menu_icon from "../assets/menu-icon.svg";
import close_icon from "../assets/close-icon.svg";
import logo from "../assets/home-logo.svg";
import { Link } from "react-router-dom";

export default function Navbar() {
  const NavLinks = [
    {
      name: "Home",
      link: "#home",
    },
    {
      name: "Info",
      link: "#info",
    },
    {
      name: "Schedule",
      link: "#schedule",
    },
    {
      name: "Merch",
      link: "#merch",
    },
    {
      name: "Contact",
      link: "#contact",
    },
  ];

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleNavLinkClick = (link, e) => {
    setMobileMenuOpen(false);
    e.preventDefault();
    const sectionId = link.substring(1);
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (mobileMenuOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  return (
    <>
      {!mobileMenuOpen && (
        <nav className="container-fluid px-0 h-100 bg-transparent text-light py-5">
          <div
            className="d-flex d-md-none my-auto ms-auto pe-4 position-sticky"
            onClick={handleMobileMenuToggle}
          >
            <img src={menu_icon} className="ms-auto" />
          </div>
          <div
            className={`container position-sticky d-none d-md-flex w-100 my-auto ms-auto ${
              mobileMenuOpen ? "d-flex flex-column" : ""
            }`}
          >
            {NavLinks.map((each, index) => (
              <a
                key={index}
                href={each.link}
                onClick={(e) => handleNavLinkClick(each.link, e)}
                className="my-auto px-4 py-2 me-2 text-light"
              >
                <p className="mb-0 fs-16 DM-bold">{each.name}</p>
              </a>
            ))}
            <div className="d-flex ms-auto">
              <Link to="https://www.instagram.com/oxlomusic/">
                <img
                  src={insta_icon}
                  alt="insta"
                  className="social-icon me-3"
                />
              </Link>
              <Link to="https://x.com/i/flow/login?redirect_after_login=%2Foxlomusic">
                <img
                  src={X_icon}
                  alt="x_twitter"
                  className="social-icon me-3"
                />
              </Link>
              <Link to="https://open.spotify.com/artist/7HwebOTQA4h0tCSTQCNKTK">
                <img
                  src={spotify_icon}
                  alt="spotify"
                  className="social-icon me-3"
                />
              </Link>
            </div>
          </div>
        </nav>
      )}

      {mobileMenuOpen && (
        <div className="d-md-none bg-dark position-sticky text-center responsive-nav p-5">
          <div
            className="d-flex d-md-none my-auto ms-auto pe-4 position-sticky"
            onClick={handleMobileMenuToggle}
          >
            <img src={close_icon} className="ms-auto" />
          </div>
          <div className="d-flex flex-column h-100">
            <div className="my-auto align-items-center">
              {NavLinks.map((each, index) => (
                <a
                  key={index}
                  href={each.link}
                  onClick={(e) => handleNavLinkClick(each.link, e)}
                  className="text-light"
                >
                  <p className="my-4 py-4 fs-28 DM-bold w-100">{each.name}</p>
                </a>
              ))}
              <img
                src={logo}
                alt="logo"
                className="my-5 py-4 mx-auto"
                width={150}
                height={110}
              />
              <div className="d-flex justify-content-center mt-auto">
                <Link to="https://www.instagram.com/oxlomusic/">
                  <img
                    src={insta_icon}
                    alt="insta"
                    className="social-icon me-3"
                  />
                </Link>
                <Link to="https://x.com/i/flow/login?redirect_after_login=%2Foxlomusic">
                  <img
                    src={X_icon}
                    alt="x_twitter"
                    className="social-icon me-3"
                  />
                </Link>
                <Link to="https://open.spotify.com/artist/7HwebOTQA4h0tCSTQCNKTK">
                  <img
                    src={spotify_icon}
                    alt="spotify"
                    className="social-icon me-3"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
