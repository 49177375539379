import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
// import Schedule from "./routes/Schedule";
// import Merch from "./routes/Merch";
// import Contact from "./routes/Contact";
import Navbar from "./components/Navbar.js";

function App() {
  return (
    <div className="">
      {/* <Navbar /> */}
      <Routes>
        {/* // INFO SCHEDULE Merch CONTACT */}
        <Route path="/" element={<Home />} />
        {/* <Route path="/schedule" element={<Schedule />} />
        <Route path="/merch" element={<Merch />} />
        <Route path="/contact" element={<Contact />} /> */}
      </Routes>
    </div>
  );
}

export default App;
