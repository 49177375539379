import React from "react";
import Navbar from "../../components/Navbar";
import logo from "../../assets/home-logo.svg";
import info_heading from "../../assets/info-heading.svg";
import sec_arrow from "../../assets/sec-arrow.svg";
import schedule_heading from "../../assets/schedule-heading.svg";
import sold_out from "../../assets/sold-out.svg";
import man_img from "../../assets/man-img.png";
import contact_heading from "../../assets/contact-heading.svg";
import schedule_mob from "../../assets/schedule-mob.svg";
import info_mob from "../../assets/info-mob.svg";
import contact_mob from "../../assets/contact-mob.svg";

export default function index() {
  const ticketsData = [
    {
      date: "07 MAY",
      location: "ORLANDO, FLORIDA",
      soldout: true,
    },
    {
      date: "13 MAY",
      location: "MANHATTAN, NEW YORK",
      soldout: true,
    },
    {
      date: "14 MAY",
      location: "MIAMI, FLORIDA",
      soldout: true,
    },
    {
      date: "17 OCT",
      location: "AMSTERDAM, NETHERLANDS",
      soldout: true,
    },
    {
      date: "19 OCT",
      location: "AMSTERDAM, NETHERLANDS",
      soldout: true,
    },
    {
      date: "14 DEC",
      location: "MUMBAI, INDIA",
      soldout: true,
    },
    {
      date: "21 DEC",
      location: "MUMBAI, INDIA",
      soldout: true,
    },
  ];

  return (
    <>
      <div className="home-bg">
        <div className="rotate-short-bg"></div>
        <Navbar />
        <div
          id="home"
          className="container mx-auto flex justify-center items-center h-screen h-100"
        >
          <div className="col-md-4 col-lg-3 text-md-end text-start">
            <img src={logo} alt="logo" className="home-logo" />
          </div>
          <div className="col-12">
            <p className="text-light fs-112 DM-500 text-center text-md-center">
              Tour Dates
            </p>
            <div className="col-8 col-md-4 col-lg-3 mx-auto mx-md-auto mb-5">
              <p className="text-light fs-28 DM-bold text-start">
                07 MAY - 21 DEC
              </p>
              <p className="text-light fs-28 DM-400 text-start">
                ORLANDO, NEW YORK, AMSTERDAM & MUMBAI
              </p>
              <div className="row mb-3 g-4">
                <button className="btn btn-warning fs-16 DM-bold mt-5 col-md-5 p-3 me-4">
                  Tickets
                </button>
                <button className="btn btn-light fs-16 DM-bold mt-5 col-md-5 p-3">
                  More Info
                </button>
              </div>
            </div>
            <div className="small-arrow-bg"></div>
          </div>
        </div>
      </div>
      <div className="short-bg"></div>
      <div id="info" className="container col-md-10 mx-auto h-100 mt-5">
        <div className="d-inline-flex">
          <img
            src={info_heading}
            alt="Info"
            className="pt-0 w-auto h-25 mt-5 d-none d-md-block"
          />
          <div className="col-10 mx-auto">
            <img
              src={info_mob}
              alt="info_mob"
              className="pt-0 w-25 my-5 d-block d-md-none"
            />
            <p className="text-light my-3 opacity-75 fs-36 DM-400">
              Join us on this electrifying journey that spans the globe, from
              the vibrant streets of Miami to legendary clubs in Amsterdam, New
              York, and Mumbai.
            </p>
            <p className="text-light my-3 fs-36 DM-400">
              Oxlo's debut World Tour promises an immersive experience,
              transporting you into the electrifying world of electronic dance
              music.
            </p>
            <p className="text-light my-3 fs-20 DM-400">
              Step into a world where music transcends boundaries and elevates
              you to new heights. Through his high-energy beats and
              unforgettable melodies, Oxlo will ignite the dancefloor, evoking
              unstoppable energy and leaving an indelible mark on your spirit.
            </p>
            <p className="text-light my-3 fs-20 DM-400">
              Stay tuned for the tour schedule, as Oxlo brings his high energy
              to cities worldwide. This is an experience you won't want to miss!
            </p>
          </div>
        </div>
      </div>
      <div className="h-100 my-5 py-5 animate-div">
        <img src={sec_arrow} className="arrow-animation" alt="arrows" />
      </div>
      <div
        id="schedule"
        className="container col-sm-10 col-md-10 col-lg-8 mx-auto h-100 mt-5"
      >
        <div className="d-inline-flex w-100 p-5">
          <img
            src={schedule_heading}
            alt="schedule"
            className="pt-0 w-auto h-25 mt-5 d-none d-md-block"
          />
          <div className="col-12 col-md-10 ms-auto">
            <img
              src={schedule_mob}
              alt="schedule_mob"
              className="pt-0 w-75 my-5 d-block d-md-none"
            />
            <div className="border-bottom mb-4"></div>
            {ticketsData.map((each, index) => (
              <div
                key={index}
                className="border-bottom mb-4 pb-4 row text-light fs-28 DM-400"
              >
                <div className="col-12 col-md-8 col-lg-9">
                  <span>
                    {each.date} - {each.location}
                  </span>
                </div>
                <div className="col-12 col-md-4 col-lg-3 d-flex justify-content-end align-items-center">
                  {each.soldout ? (
                    <img
                      src={sold_out}
                      alt="sold-out"
                      className="d-none d-md-block"
                    />
                  ) : null}
                  <button className="btn btn-light fs-16 DM-bold px-3 d-none d-md-block ms-3">
                    Tickets
                  </button>
                </div>
                <div className="col-12 d-block d-md-none">
                  <button className="btn btn-light fs-16 DM-bold w-100 py-1">
                    <span>Tickets</span>
                    {each.soldout ? (
                      <img
                        src={sold_out}
                        alt="sold-out"
                        className="ms-3"
                        width={55}
                        height={25}
                      />
                    ) : null}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div id="merch" className="container mx-auto my-5 py-5">
        <img src={man_img} alt="potrait" className="potrait-size" />
      </div>

      <div id="contact" className="container col-md-10 h-100 py-5 my-5">
        <div className="d-inline-flex w-100">
          <img
            src={contact_heading}
            alt="contact"
            className="pt-0 w-auto h-25 mt-5 d-none d-md-block"
          />
          <div className="col-md-10 mx-auto">
            <img
              src={contact_mob}
              alt="contact_mob"
              className="pt-0 w-75 my-5 d-block d-md-none"
            />
            <p className="text-light my-3 fs-20 DM-400">
              Want to book Oxlo? Fill out the form below and we will kindly get
              back to you.
            </p>
            <form className="row col-md-9 g-3">
              <div className="col-12 mb-2">
                <label for="inputFullname" className="form-label text-light">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputFullname"
                  placeholder="Fullname"
                />
              </div>
              <div className="col-12 mb-2">
                <label for="inputEmail" className="form-label text-light">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail"
                  placeholder="Email"
                />
              </div>
              <div className="col-12 mb-2">
                <label for="inputphone" className="form-label text-light">
                  Phone
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="inputphone"
                  placeholder="phone"
                />
              </div>
              <div className="col-12 mb-2">
                <label for="inputMessage" className="form-label text-light">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="inputMessage"
                  rows="5"
                  placeholder="Message"
                ></textarea>
              </div>
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-warning fs-16 DM-bold col-12 col-lg-2 col-md-3"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="rotate-short-bg"></div>
      <div className="container mx-auto overflow-hidden">
        <div className="col-5 col-md-2 ms-auto">
          <img
            src={logo}
            alt="logo"
            className="position-sticky"
            style={{ height: "200px" }}
          />
        </div>
      </div>
      <footer className="container-fluid text-light text-center py-3">
        <p className="fs-14 DM-bold">
          OxloMusic.com All rights reserved © 2024.
        </p>
      </footer>
    </>
  );
}
